.login-main {
  position: relative;
  overflow: hidden; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.login-main::before {
  content: ""; 
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  background-image: url('../assets/login-bg.jpeg');
  background-position: center;
  background-size: cover;
  animation: zoom 25s infinite; 
  z-index: 0; 
}


@keyframes zoom {
  0% {
      transform: scale(1); /* Start at normal size */
  }
  50% {
      transform: scale(1.15); /* Zoom in */
  }
  100% {
      transform: scale(1); 
  }
}


.login-main > * {
  position: relative; /* Ensure the content is on top */
  z-index: 1; 
}

  .footer {
    position: fixed;
    height: 50px;
    color: #fff;
    padding-top: 20px;
    text-align: center;
    bottom: 10px;
    left: 0;
    right: 0;
  }
  
  .login-form {
    color: white;
    border-radius: 10px;
  }
  
  .footer p {
    margin-top: 20px;
    color: #ffffff;
  }
  
  .text-center h3 {
    color: white;
    font-weight: bold;
    font-size: 22px;
  }
  
   .transparent-input {
    background-color: rgba(255, 255, 255, 0.1)  !important;
    border: 1px solid rgba(255, 255, 255, 0.2)  !important;
    color: white   !important;
  }

   .transparent-input::placeholder {
    color: white !important; /* Change this to your desired color */
    opacity: 1;   /* Ensures the color is fully opaque */
}
