/* Default transparent background for all screens */
.navbar-collapse-custom {
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  /* White background only for small screens (mobile) */
  @media (max-width: 991px) {
    .navbar-collapse-custom {
      background-color: #fefefe;
      z-index: 1000;
    }
  }
  
  

  .nav-link {
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .nav-link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #0056b3;
    transition: width 0.3s ease-in-out;
  }
  
  .nav-link:hover::after {
    width: 90%;
  }
  
  .nav-link:hover {
    color: #0056b3 !important;
    transform: scale(1.1);
  }
  




  