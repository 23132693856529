.SignUp-main, .SignUp-main2 {
    position: relative;
    overflow: hidden; 
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .SignUp-main::before {
    content: ""; 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    background-image: url('../../assets/Truck03.jpg');
    background-position: center;
    background-size: cover;
    z-index: 0; 
    background-color: rgba(255, 153, 0, 0.665);
    /* color: #ffffff50; */
  }
  
  
  .SignUp-main2::before {
    content: ""; 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    background-image: url('../../assets/truck5.jpg');
    background-position: center;
    background-size: cover;
    z-index: 0; 
    background-color: rgba(255, 153, 0, 0.665);
  }
  
  

  .SignUp-main > * {
    position: relative; /* Ensure the content is on top */
    z-index: 1; 
  }

  .SignUp-main2 > * {
    position: relative; /* Ensure the content is on top */
    z-index: 1; 
  }
  
  
    .footer {
      position: fixed;
      height: 50px;
      color: #fff;
      padding-top: 20px;
      text-align: center;
      bottom: 10px;
      left: 0;
      right: 0;
    }
    
    .SignUp-form {
      color: white;
      border-radius: 10px;
    }
    
    .footer p {
      margin-top: 20px;
      color: #ffffff;
    }
    
    .text-center h3 {
      color: white;
      font-weight: bold;
      font-size: 22px;
    }

  .transparent-input {
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    color: white !important;
  }
  
  .transparent-input option {
    background-color: rgba(0, 0, 0, 0.9); /* Dark background for dropdown options */
    color: white;
  }
  