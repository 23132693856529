.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
}

.feedback-container {
  background-color: #BCBCBC; /* Semi-transparent */
  backdrop-filter: blur(10px); 
  border-radius: 8px;
  padding: 20px 25px;
  width: 45%; /* Default width for larger screens */
  height: auto; /* Adjust height automatically based on content */
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.feedback-title {
  font-size: 22px;
  color: black;
  margin-bottom: 5px;
  text-align: center;
}

.feedback-subtitle {
  font-size: 13px;
  color: white;
  margin-bottom: 20px;
  text-align: center;
  font-weight: normal;
}

.feedback-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-input,
.form-textarea {
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-textarea {
  resize: none;
}

.form-submit {
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #333333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-submit:hover {
  background-color: #444444;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .feedback-container {
    width: 60%; /* Adjust width for medium-sized screens */
  }
}

@media (max-width: 768px) {
  .feedback-container {
    width: 80%; /* Adjust width for tablets */
  }

  .feedback-title {
    font-size: 20px; /* Slightly smaller title */
  }

  .feedback-subtitle {
    font-size: 12px; /* Adjust subtitle size */
  }

  .form-input,
  .form-textarea {
    font-size: 13px; /* Smaller input text size */
  }

  .form-submit {
    font-size: 14px; /* Smaller button text size */
  }
}

@media (max-width: 480px) {
  .feedback-container {
    width: 90%; /* Full width for smaller mobile devices */
    padding: 15px; /* Reduce padding */
  }

  .feedback-title {
    font-size: 18px; /* Adjust title size */
  }

  .feedback-subtitle {
    font-size: 11px; /* Adjust subtitle size */
  }

  .form-input,
  .form-textarea {
    font-size: 12px; /* Adjust text size for inputs */
    padding: 8px; /* Reduce padding */
  }

  .form-submit {
    font-size: 12px; /* Smaller button text size */
    padding: 8px; /* Adjust button padding */
  }
}



.footer {
  position: fixed;

  height: 50px;
  color: #fff;
  padding-top: 20px;
  text-align: center;
  margin-bottom: 15px;
  bottom: 10px;
  left: 0;
  right: 0;
}