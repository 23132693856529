/* Loading.css */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9; /* Optional: Light background */
    color: #333; /* Optional: Font color */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3; /* Light gray */
    border-top: 6px solid #3498db; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  p {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  